import "./RefineSearch.vue";
import "./RefineSearchBtn.vue";
import "./searchTypes/YmmSearch.vue";
import "./searchTypes/PartNumberSearch.vue";
import "./searchTypes/LicensePlateTabSearch.vue";
import "./searchTypes/VinSearch.vue";
import "./searchTypes/EngineSearch.vue";
import "./searchTypes/SpecificationSearch.vue";


if (document.querySelector('[data-component="driv-part-results-refine-search"]')) {
    new Vue({
        el: '[data-component="driv-part-results-refine-search"]',
    });
}
