<template>
    <div class="refine-search-fields">
        <fmmp-autocomplete
            class="driv-part-common state-dropdown"
            :placeholder="dropdownPlaceholder"
            :config="autocompleteConfig"
            :items="states"
            v-model="currentState"
            :showLabel="showLabels"
        ></fmmp-autocomplete>
        <div class="input-container">
            <label v-if="showLabels" class="field-label">{{  licensePlateNumberPlaceholder }}</label>
            <input
                type="text"
                maxlength="7"
                :placeholder="licensePlateNumberPlaceholder"
                v-model="currentLicensePlateNumber"
                @input="inputChangeHandler"
            />
            <fmmp-error-message
                v-if="errorMessage"
                :small_desktop="true"
                message="invalid license plate number"
            />
        </div>
        <driv-refine-search-btn :disabled="isDisabled" :onClick="handleSearch">
        </driv-refine-search-btn>
        <fmmp-error-message
            v-if="errorMessage"
            :tablet="true"
            message="invalid license plate number"
        />
    </div>
</template>

<script>
import "../../../../common/search-error-message/ErrorMessage.vue";
import {
    PART_FINDER_CORPORATE_SEARCH_TYPES,
    VIN_LP_VEHICLE_TYPE,
} from "../../../../common/partFinderCorporate.constants";
import {
    createQueryStringForFilters,
    createUrlToGoToSearchResults,
    getTranslation,
    mapVinOrLicensePlateResponse,
    isGPI,
} from "../../../../common/partFinderCorporate.helpers";

export default Vue.component("driv-refine-license-plate-search", {
    data() {
        return {
            currentState: null,
            currentLicensePlateNumber: null,
            dropdownPlaceholder: this.getTranslation("State"),
            autocompleteConfig: {
                minLength: 0,
                delay: 0,
                html: false,
                maxHeight: "300px",
                onlySelectValid: true,
                searchOnFocus: false,
            },
            errorMessage: false,
            licensePlateNumberPlaceholder: this.getTranslation('USA License Plate Number'),
        };
    },
    props: {
        states: {
            type: Array,
            default: () => [],
        },
        stateId: {
            type: String,
            default: "",
        },
        licensePlateNumber: {
            type: String,
            default: "",
        },
        subBrand: {
            type: String,
            default: "",
        },
        showLabels: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        getTranslation,
        handleSearch() {
            Vue.CatalogApi.CatalogApiService.getLicensePlateList({
                plateNumber: this.currentLicensePlateNumber,
                code: this.currentState?.id,
            })
                .then((resp) => {
                    const { year, make, model, ...filtersValues } = resp;
                    const ymmValues = mapVinOrLicensePlateResponse({ year, make, model });
                    if (this.resultsPagePath) {
                        const queryObj = {
                            ...ymmValues,
                            type: { value: VIN_LP_VEHICLE_TYPE },
                            searchType: PART_FINDER_CORPORATE_SEARCH_TYPES.VEHICLE,
                        };

                        const url = createUrlToGoToSearchResults({
                            path: this.resultsPagePath,
                            queryObj,
                        });

                        const urlWithFilters = `${url}#${createQueryStringForFilters(
                            filtersValues,
                        )}`;

                        Vue.Global.Analytics.trackEvent(
                            "part-search",
                            "search-part-filter",
                            `${this.currentState?.label} ~ ${this.currentLicensePlateNumber}`,
                            null,
                            {
                                callback: function () {
                                    window.location.assign(urlWithFilters);
                                },
                            },
                        );
                    }
                })
                .catch(() => {
                    this.errorMessage = true;
                });
        },
        inputChangeHandler() {
            this.errorMessage = false;
        },
    },
    computed: {
        isDisabled() {
            return !(this.currentState && this.currentLicensePlateNumber);
        },
        resultsPagePath: () => {
            return window.location.pathname.replace(".html", "");
        },
    },
    created() {
        if (this.licensePlateNumber) {
            this.currentLicensePlateNumber = this.licensePlateNumber;
        }

        if (this.stateId) {
            const stateObj = this.states.find(({ id }) => id == this.stateId);
            if (stateObj) {
                this.currentState = stateObj;
            }
        }

        setTimeout(() => {
            const eleContainer = document.querySelector('.state-dropdown');
            const isvalueSelected = eleContainer.querySelector('.selected-tag');
            if(isGPI() && !isvalueSelected) {
                eleContainer.querySelector('input').focus();
            }
        }, 0);
    },
    watch: {
        currentState: {
            handler: function () {
                if (this.errorMessage) this.errorMessage = false;
            },
        },
    },
});
</script>
