import { getModelFromFieldObject } from "../../../common/engine-search/helpers";
import { SPECIFICATION_TORQUE } from "../../../common/partFinderCorporate.constants";

export const checkForEnginePerformance = (initialField) => {
    const fieldId = getModelFromFieldObject(initialField)?.value;
    const fieldIdArray = fieldId.split(",");
    const performanceIds = Vue.PartFinder.LOOK_UP_IDS.ENGINE_PERFORMANCE.split(",");

    return performanceIds.some((id) => fieldIdArray.includes(id));
};

export const checkForTorque = (initialField) => {
    const fieldId = getModelFromFieldObject(initialField)?.value;
    if (!fieldId) return false;

    const fieldIdArray = fieldId.split(",");
    const torqueIds = SPECIFICATION_TORQUE.split(",");

    return torqueIds.some((id) => fieldIdArray.includes(id));
};
