<template>
    <div class="refine-search-fields">
        <fmmp-autocomplete
            v-if="isApplicationTypeShown"
            :class="`driv-part-common ${initialField.name} ${searchFieldWidthClass}`"
            :config="autocompleteConfig"
            :id="initialField.name"
            :name="initialField.name"
            :items="initialField.items"
            v-model="initialField.model"
            :placeholder="getTranslation(initialField.placeholder)"
            :loading="initialField.loading"
            @input="initialField.onInput"
            :showLabel="showLabels"
        ></fmmp-autocomplete>
        <template v-if="initialField.model">
            
            <fmmp-autocomplete
                v-for="field in options"
                :key="field"
                :class="`driv-part-common ${field} ${searchFieldWidthClass}`"
                :config="autocompleteConfig"
                :id="field"
                :name="field"
                :items="fieldsConfig[field].items"
                :disabled="fieldsConfig[field].disabled"
                v-model="fieldsConfig[field].model"
                :placeholder="getTranslation(fieldsConfig[field].placeholder)"
                :loading="fieldsConfig[field].loading"
                @input="(value) => handleFieldInput(field, value)"
                :withImage="field === FIELDS.BRAND"
                :showLabel="showLabels"
            ></fmmp-autocomplete>
            <fmmp-autocomplete
                v-if="isDescriptionShown"
                :key="descField"
                :class="`driv-part-common ${descField} ${searchFieldWidthClass}`"
                :config="autocompleteConfig"
                :id="descField"
                :name="descField"
                :items="fieldsConfig[descField].items"
                :disabled="fieldsConfig[descField].disabled"
                v-model="fieldsConfig[descField].model"
                :placeholder="getTranslation(fieldsConfig[descField].placeholder)"
                :loading="fieldsConfig[descField].loading"
                @input="(value) => handleFieldInput(descField, value)"
                :showLabel="showLabels"
            ></fmmp-autocomplete>
            <driv-refine-search-btn
                v-if="searchButton"
                :disabled="searchButton.isDisabled"
                :onClick="handleSearch"
            >
            </driv-refine-search-btn>
        </template>
        <div v-else class="search-txt desktop">{{ searchValuesString }}</div>
    </div>
</template>

<script>
import {
    EMPTY_ITEM,
    FIELDS,
    PART_DESCRIPTION_ATTRIBUTE_NAME,
} from "../../../../common/specification-search/constants";
import configs from "../../../../common/specification-search/SpecificationSearchConfig";
import {
    createUrlToGoToSearchResults,
    getTranslation,
} from "../../../../common/partFinderCorporate.helpers";
import { getOptionsConfigBySelectedApplicationTypes } from "../../../../common/ymm-search/helpers";
import {
    getBrandsOptionsFromString,
    getModelFromFieldObject,
    getTorqueContentSilosFromString,
    resetField,
} from "../../../../common/specification-search/helpers";
import {
    PART_FINDER_CORPORATE_SEARCH_TYPES,
    SPECIFICATION_PARTS,
} from "../../../../common/partFinderCorporate.constants";
import { SPECIFICATION_PROPERTIES } from "../../../../driv-part-finder-part-results-page/clientlibs/src/constants";
import { getHashParams } from "../../../../driv-part-finder-part-results-page/clientlibs/src/helpers";
import { checkForTorque } from "../helpers";

const { fieldsConfig, fieldsOptionsFromAEM, optionsConfig, searchButton, initialField } = configs;

export default Vue.component("driv-refine-specification-search", {
    props: {
        searchParams: {
            specificationType: {},
            brands: {},
            partTypeId: {},
            partDescription: {},
        },
        specificationTypes: {
            type: String,
            default: "",
        },
        specificationBrandType: {
            type: String,
            default: "",
        },
        searchValuesString: {
            type: String,
            default: "",
        },
        torqueContentSilos: {
            type: String,
            default: "",
        },
        showLabels: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            autocompleteConfig: {
                minLength: 0,
                delay: 0,
                html: false,
                maxHeight: "300px",
                onlySelectValid: true,
                searchOnFocus: false,
            },
            optionsConfig,
            fieldsConfig,
            searchButton,
            initialField,
            isApplicationTypeShown: true,
            FIELDS,
            descField: FIELDS.PART_DESCRIPTION,
        };
    },
    computed: {
        config() {
            return getOptionsConfigBySelectedApplicationTypes({
                optionsConfig: this.optionsConfig,
                type: this.initialField.model,
            });
        },
        options() {
            return Object.keys(this.config.fields).filter(
                (option) => option !== FIELDS.PART_DESCRIPTION && this.fieldsConfig[option],
            );
        },
        isDescriptionShown() {
            return (
                this.initialField.model.value === SPECIFICATION_PARTS &&
                this.fieldsConfig[FIELDS.PART_TYPE].items.length &&
                this.fieldsConfig[FIELDS.PART_DESCRIPTION].items.length &&
                this.fieldsConfig[FIELDS.PART_DESCRIPTION].items[0]?.label !== EMPTY_ITEM.label
            );
        },
        searchFieldWidthClass() {
            let widthClass = "";
            if (this.initialField.model?.value) {
                const specificationFieldsQty =
                    (this.isApplicationTypeShown ? 1 : 0) + this.options.length;
                widthClass = `width-${specificationFieldsQty}`;
            }
            return widthClass;
        },
        resultsPagePath: () => {
            return window.location.pathname.replace(".html", "");
        },
        isTorqueSearch() {
            return checkForTorque(this.initialField);
        },
        currentSearchType() {
            if (this.isTorqueSearch) {
                return PART_FINDER_CORPORATE_SEARCH_TYPES.TORQUE;
            }
            return PART_FINDER_CORPORATE_SEARCH_TYPES.SPECIFICATION;
        },
    },
    watch: {
        "initialField.items": {
            handler: function (newItems) {
                if (newItems.length === 1) {
                    const resolveAllDependentFields =
                        this.searchParams.specificationType?.value === newItems[0].value;
                    this.initialField.model = newItems[0];
                    this.initialField.onInput(newItems[0], resolveAllDependentFields);
                    resolveAllDependentFields && this.setSpecificationOptionsValue();
                } else if (this.searchParams.specificationType?.value) {
                    const option = this.initialField.items.find(
                        ({ value }) => value === this.searchParams.specificationType.value,
                    );

                    if (option) {
                        this.initialField.model = option;
                        this.setSpecificationOptionsValue();
                        this.initialField.onInput(option, true);
                    }
                }
            },
            immediate: true,
        },
    },
    methods: {
        getTranslation,
        handleFieldInput(field, value) {
            this.config.fields[field].onInput(value);
        },
        prepareEventParamsForAnalytics({ specificationType, contentSilos, hashString, queryObj }) {
            const eventParams = Object.values(queryObj).reduce((acc, item) => {
                if (!this.isTorqueSearch) {
                    if (item.label) {
                        acc = [...acc, item.label];
                    }
                    return [...acc];
                }
                return [specificationType.label, contentSilos.label];
            }, []);

            if (!this.isTorqueSearch && hashString) {
                eventParams.push(hashString.attribute_filters[0].value);
            }

            return eventParams;
        },
        handleSearch() {
            if (this.resultsPagePath) {
                const specificationType = getModelFromFieldObject(this.initialField);
                const brands = getModelFromFieldObject(this.fieldsConfig[FIELDS.BRAND]);
                const partTypeId = getModelFromFieldObject(this.fieldsConfig[FIELDS.PART_TYPE]);
                const partDescription = getModelFromFieldObject(
                    this.fieldsConfig[FIELDS.PART_DESCRIPTION],
                );
                const contentSilos = getModelFromFieldObject(
                    this.fieldsConfig[FIELDS.TORQUE_CONTENT_SILOS],
                );

                const queryObj = {
                    searchType: this.currentSearchType,
                    specificationType,
                    partTypeId,
                    brands: { label: brands.label, value: brands.value },
                    contentSilos: { label: contentSilos.label, value: contentSilos.value },
                };

                const hashString = partDescription?.value
                    ? {
                          [SPECIFICATION_PROPERTIES.ATTRIBUTE_FILTERS]: [
                              {
                                  name: PART_DESCRIPTION_ATTRIBUTE_NAME,
                                  value: partDescription?.value,
                              },
                          ],
                      }
                    : "";

                const url = createUrlToGoToSearchResults({
                    queryObj,
                    path: this.resultsPagePath,
                    hashString,
                });

                const eventParams = this.prepareEventParamsForAnalytics({
                    queryObj,
                    hashString,
                    contentSilos,
                    specificationType,
                });

                Vue.Global.Analytics.trackEvent(
                    "part-search",
                    "search-part-filter",
                    eventParams.join("|"),
                    null,
                    {
                        callback: function () {
                            window.location.assign(url);
                        },
                    },
                );
            }

            return false;
        },
        getPartDescription() {
            const hashValue = getHashParams();
            const filters = hashValue[SPECIFICATION_PROPERTIES.ATTRIBUTE_FILTERS];
            if (filters) {
                const partDescriptionFilter = filters?.find(
                    (filter) => filter.name === PART_DESCRIPTION_ATTRIBUTE_NAME,
                );

                const value = partDescriptionFilter?.value;

                if (value) return { value, label: value };
            }
        },
        setSpecificationOptionsValue() {
            const searchData = {
                [FIELDS.BRAND]: this.searchParams.brands,
                [FIELDS.TYPE]: this.searchParams.specificationType,
                [FIELDS.PART_TYPE]: this.searchParams.partTypeId,
                [FIELDS.TORQUE_CONTENT_SILOS]: this.searchParams.contentSilos,
            };

            const partDescription = this.getPartDescription();

            this.options.forEach((option) => {
                if (!searchData[option]?.value) return;
                this.fieldsConfig[option].model = searchData[option];
                this.fieldsConfig[option].disabled = false;
            });

            if (!partDescription?.value) return;

            this.fieldsConfig[FIELDS.PART_DESCRIPTION].model = partDescription;
            this.fieldsConfig[FIELDS.PART_DESCRIPTION].disabled = false;
        },
    },
    created() {
        if (this.specificationTypes) {
            this.initialField.getItems(this.specificationTypes);

            if (this.specificationBrandType) {
                fieldsOptionsFromAEM[FIELDS.BRAND] = getBrandsOptionsFromString(
                    this.specificationBrandType,
                );
            }
            if (this.torqueContentSilos) {
                fieldsOptionsFromAEM[FIELDS.TORQUE_CONTENT_SILOS] = getTorqueContentSilosFromString(
                    this.torqueContentSilos,
                );
            }
        }
    },
    beforeDestroy() {
        resetField(this.initialField);
    },
});
</script>
