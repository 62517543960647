<template>
    <div class="refine-search-fields">
        <fmmp-autocomplete
            v-if="isApplicationTypeShown"
            :class="`driv-part-common ${initialField.name} ${ymmFieldWidthClass}`"
            :config="autocompleteConfig"
            :id="initialField.name"
            :name="initialField.name"
            :items="initialField.items"
            v-model="initialField.model"
            :placeholder="getTranslation(initialField.placeholder)"
            :loading="initialField.loading"
            @input="(value) => initialField.onInput(value, subBrand)"
            :showLabel="showLabels"
        ></fmmp-autocomplete>
        <template v-if="initialField.model">
            <fmmp-autocomplete
                v-for="field in options"
                :key="field"
                :class="`driv-part-common ${field} ${ymmFieldWidthClass}`"
                :config="autocompleteConfig"
                :id="field"
                :name="field"
                :items="fieldsConfig[field].items"
                :disabled="fieldsConfig[field].disabled"
                v-model="fieldsConfig[field].model"
                :placeholder="getTranslation(fieldsConfig[field].placeholder)"
                :loading="fieldsConfig[field].loading"
                @input="(value) => handleFieldInput(field, value)"
                :showLabel="showLabels"
            ></fmmp-autocomplete>
            <driv-refine-search-btn
                v-if="searchButton"
                :disabled="searchButton.isDisabled"
                :onClick="handleSearch"
            >
            </driv-refine-search-btn>
        </template>
        <div v-else class="search-txt desktop">{{ searchValuesString }}</div>
    </div>
</template>

<script>
import {
    createUrlToGoToSearchResults,
    getTranslation,
} from "../../../../common/partFinderCorporate.helpers";
import configs from "../../../../common/ymm-search/YMMSearchConfig";
import {
    getModelFromFieldObject,
    getOptionsConfigBySelectedApplicationTypes,
    resetField,
} from "../../../../common/ymm-search/helpers";
import { FIELDS } from "../../../../common/ymm-search/constants";
import { PART_FINDER_CORPORATE_SEARCH_TYPES } from "../../../../common/partFinderCorporate.constants";

const { fieldsConfig, optionsConfig, searchButton, initialField } = configs;

export default Vue.component("driv-refine-ymm-search", {
    data() {
        return {
            autocompleteConfig: {
                minLength: 0,
                delay: 0,
                html: false,
                maxHeight: "300px",
                onlySelectValid: true,
                searchOnFocus: false,
            },
            optionsConfig,
            fieldsConfig,
            searchButton,
            initialField,
            isApplicationTypeShown: true,
        };
    },
    props: {
        searchParams: {
            vehicleType: {},
            year: {},
            make: {},
            model: {},
            vehicle: {},
            mfr: {},
            equipmentModel: {},
            equipmentYear: {},
        },
        vehicleTypes: {
            type: String,
            default: "",
        },
        defaultVehicleTypeId: {
            type: String,
            default: "",
        },
        searchValuesString: {
            type: String,
            default: "",
        },
        subBrand: {
            type: String,
            default: "",
        },
        showLabels: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        resultsPagePath: () => {
            return window.location.pathname.replace(".html", "");
        },
        config() {
            return getOptionsConfigBySelectedApplicationTypes({
                optionsConfig: this.optionsConfig,
                type: this.initialField.model,
            });
        },
        options() {
            return Object.keys(this.config.fields).filter((option) => fieldsConfig[option]);
        },
        ymmFieldWidthClass() {
            let widthClass = "";

            if (this.initialField.model?.value) {
                const ymmFieldsQty = (this.isApplicationTypeShown ? 1 : 0) + this.options.length;
                widthClass = `width-${ymmFieldsQty}`;
            }
            return widthClass;
        },
    },
    watch: {
        "initialField.items": function (newItems) {
            if (newItems.length === 1) {
                const resolveAllDependentFields =
                    this.searchParams.vehicleType?.value === newItems[0].value;

                this.initialField.model = newItems[0];
                this.isApplicationTypeShown = false;
                this.initialField.onInput(newItems[0], this.subBrand, resolveAllDependentFields);
                resolveAllDependentFields && this.setVehicleOptionsValue();
            } else if (this.searchParams.vehicleType?.value) {
                const option = this.initialField.items.find(
                    ({ value }) => value === this.searchParams.vehicleType.value,
                );

                if (option) {
                    this.initialField.model = option;
                    this.initialField.onInput(option, this.subBrand, true);
                    this.setVehicleOptionsValue();
                }
            } else if (this.defaultVehicleTypeId) {
                const option = newItems.find(({ value }) => value === this.defaultVehicleTypeId);

                if (option) {
                    this.initialField.model = option;
                    this.initialField.onInput(option, this.subBrand);
                }
            }
        },
    },
    methods: {
        getTranslation,
        handleFieldInput(field, value) {
            this.config.fields[field].onInput(value, this.subBrand);
        },
        handleSearch() {
            if (this.resultsPagePath) {
                const optionsObj = this.options.reduce((acc, optKey) => {
                    acc[optKey] = true;
                    return acc;
                }, {});

                const getSelectedValue = (key) =>
                    optionsObj[key] && getModelFromFieldObject(this.fieldsConfig[key]);

                const type = getModelFromFieldObject(this.initialField);
                const year = getSelectedValue(FIELDS.YEAR);
                const make = getSelectedValue(FIELDS.MAKE);
                const model = getSelectedValue(FIELDS.MODEL);
                const vehicle = getSelectedValue(FIELDS.VEHICLE);
                const mfr = getSelectedValue(FIELDS.MANUFACTURER);
                const equipmentModel = getSelectedValue(FIELDS.EQUIPMENT_MODEL);
                const equipmentYear = getSelectedValue(FIELDS.EQUIPMENT_YEAR);
                const queryObj = {
                    searchType: PART_FINDER_CORPORATE_SEARCH_TYPES.VEHICLE,
                    type,
                    ...(year?.value ? { year } : {}),
                    ...(make?.value ? { make } : {}),
                    ...(model?.value ? { model } : {}),
                    ...(vehicle?.value ? { vehicle } : {}),
                    ...(mfr?.value ? { mfr } : {}),
                    ...(equipmentModel?.value ? { equipmentModel } : {}),
                    ...(equipmentYear?.value ? { equipmentYear } : {}),
                };

                const url = createUrlToGoToSearchResults({ queryObj, path: this.resultsPagePath });

                const eventParams = [
                    "",
                    type.value,
                    Object.values(queryObj)
                        .map((item) => item.label)
                        .join("~"),
                    Object.values(queryObj)
                        .map((item) => item.value)
                        .join("~"),
                ].join("|");

                Vue.Global.Analytics.trackEvent(
                    "part-search",
                    "search-part-filter",
                    eventParams,
                    null,
                    {
                        callback: function () {
                            window.location.assign(url);
                        },
                    },
                );
            }
        },
        setVehicleOptionsValue() {
            const searchData = {
                [FIELDS.YEAR]: this.searchParams.year,
                [FIELDS.MAKE]: this.searchParams.make,
                [FIELDS.MODEL]: this.searchParams.model,
                [FIELDS.VEHICLE]: this.searchParams.vehicle,
                [FIELDS.MANUFACTURER]: this.searchParams.mfr,
                [FIELDS.EQUIPMENT_MODEL]: this.searchParams.equipmentModel,
                [FIELDS.EQUIPMENT_YEAR]: this.searchParams.equipmentYear,
            };

            this.options.forEach((option) => {
                if (!searchData[option]?.value) return;

                this.fieldsConfig[option].model = searchData[option];
                this.fieldsConfig[option].disabled = false;
            });
        },
    },
    created() {
        if (this.vehicleTypes) {
            initialField.getItems(this.vehicleTypes);
        }
    },
    beforeDestroy() {
        resetField(initialField);
    },
});
</script>
