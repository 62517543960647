import { render, staticRenderFns } from "./partDetailPage.vue?vue&type=template&id=4f1731f1&"
import script from "./partDetailPage.vue?vue&type=script&lang=js&"
export * from "./partDetailPage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/build_root/build/federalmogul/aem-base/content/src/main/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4f1731f1')) {
      api.createRecord('4f1731f1', component.options)
    } else {
      api.reload('4f1731f1', component.options)
    }
    module.hot.accept("./partDetailPage.vue?vue&type=template&id=4f1731f1&", function () {
      api.rerender('4f1731f1', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "content/jcr_root/apps/fmmp-base/components/content/driv-part-finder/driv-part-detail-page/clientlibs/src/partDetailPage.vue"
export default component.exports