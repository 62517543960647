import { render, staticRenderFns } from "./documentsAutocomplete.vue?vue&type=template&id=7c0dd426&"
import script from "./documentsAutocomplete.vue?vue&type=script&lang=js&"
export * from "./documentsAutocomplete.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/build_root/build/federalmogul/aem-base/content/src/main/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('7c0dd426')) {
      api.createRecord('7c0dd426', component.options)
    } else {
      api.reload('7c0dd426', component.options)
    }
    module.hot.accept("./documentsAutocomplete.vue?vue&type=template&id=7c0dd426&", function () {
      api.rerender('7c0dd426', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "content/jcr_root/apps/fmmp-base/components/content/documents-autocomplite/documents-autocomplete/clientlibs/src/documentsAutocomplete.vue"
export default component.exports