<template>
    <form class="part-number-search-form" @submit.prevent.stop="handleSearch">
        <button type="submit" class="button-search button-icon"></button>
        <input
            type="text"
            :placeholder="partNumberSearchPlaceholder"
            v-model="partNumberValue"
            required
        />
    </form>
</template>

<script>
import {
    createUrlToGoToSearchResults,
    getTranslation,
} from "../../../common/partFinderCorporate.helpers";
import { PART_FINDER_CORPORATE_SEARCH_TYPES } from "../../../common/partFinderCorporate.constants";

export default Vue.component("driv-corp-part-number-search-box", {
    props: {
        partSearchResultsPath: String,
    },
    data() {
        return {
            partNumberSearchPlaceholder: getTranslation("Part # Search"),
            partNumberValue: null,
        };
    },
    computed: {
        url() {
            return createUrlToGoToSearchResults({
                path: this.partSearchResultsPath,
                queryObj: {
                    part: this.partNumberValue,
                    searchType: PART_FINDER_CORPORATE_SEARCH_TYPES.PART_NUMBER_SEARCH,
                },
            });
        },
    },
    methods: {
        handleSearch() {
            if (this.partSearchResultsPath) {
                const url = this.url;

                Vue.Global.Analytics.trackEvent(
                    "part-search",
                    "search-part-number",
                    this.partNumberValue,
                    null,
                    {
                        callback: function () {
                            window.location.assign(url);
                            return false;
                        },
                    },
                );
            }
        },
    },
});
</script>
