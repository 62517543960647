import "./partFinderCorporate.vue";
import "./vehicleTab.vue";
import "./engineTab.vue";
import "./licensePlateTab.vue";
import "./vinTab.vue";
import "./specificationTab.vue";

if (document.querySelector('[data-component="driv-part-finder-corporate"]')) {
    new Vue({
        el: '[data-component="driv-part-finder-corporate"]',
    });
}
