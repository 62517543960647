var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "documents-search-container",
      class: { napa: _vm.isNapaBrand() }
    },
    [
      _c(
        "div",
        {
          staticClass: "documents-search",
          style: !_vm.isNapaBrand()
            ? { backgroundImage: "url(" + _vm.bgImagePath + ")" }
            : { backgroundColor: _vm.napaBgColor }
        },
        [
          _c("div", { staticClass: "search-description" }, [
            _vm.isNapaBrand()
              ? _c("img", {
                  staticClass: "logo",
                  attrs: { src: _vm.napaLogoPath, alt: "Logo" }
                })
              : _vm._e(),
            _vm._v(" "),
            _c("h3", { staticClass: "installation-instructions-title" }, [
              _c("span", { staticClass: "installation" }, [
                _vm._v(
                  "\n                    " +
                    _vm._s(_vm.searchTitle) +
                    "\n                "
                )
              ])
            ]),
            _vm._v(" "),
            _c("h5", { staticClass: "installation-instructions-subtitle" }, [
              _vm._v(
                "\n                " +
                  _vm._s(_vm.searchDescription) +
                  "\n            "
              )
            ])
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "async-autocomplete-container" },
            [
              _c("fmmp-async-autocomplete", {
                attrs: {
                  search: _vm.onSearch,
                  onChange: _vm.onChange,
                  placeholder: _vm.searchPlaceholder,
                  options: _vm.options,
                  noOptionsText: _vm.noOptionsText,
                  sku: _vm.partNumberFromQuery
                }
              })
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _vm.documentsLoading
        ? _c("div", { staticClass: "three-quarters-loader" })
        : _vm.documents.length > 0
        ? _c(
            "div",
            { staticClass: "search-result-container" },
            [
              _c("search-results", {
                attrs: {
                  partNumber: _vm.selectedPartNumber,
                  documents: _vm.documents,
                  buttonText: _vm.buttonText
                }
              })
            ],
            1
          )
        : _vm.documents.length === 0 && _vm.selectedPartNumber
        ? _c("h4", { staticClass: "not-found" }, [
            _vm._v(
              "\n        " +
                _vm._s(
                  _vm.getTranslation(
                    "We’re sorry, we don’t have an installation guide for the product"
                  )
                ) +
                " " +
                _vm._s(_vm.selectedPartNumber) +
                "\n        " +
                _vm._s(_vm.getTranslation("you have entered")) +
                "\n    "
            )
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }