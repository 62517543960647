<template>
    <div class="documents-search-container" :class="{ napa: isNapaBrand() }">
        <div :style="!isNapaBrand()
                ? { backgroundImage: 'url(' + bgImagePath + ')' }
                : { backgroundColor: napaBgColor }
            " class="documents-search">
            <div class="search-description">
                <img v-if="isNapaBrand()" class="logo" :src="napaLogoPath" alt="Logo" />
                <h3 class="installation-instructions-title">
                    <span class="installation">
                        {{ searchTitle }}
                    </span>
                </h3>
                <h5 class="installation-instructions-subtitle">
                    {{ searchDescription }}
                </h5>
            </div>
            <div class="async-autocomplete-container">
                <fmmp-async-autocomplete
                    :search="onSearch"
                    :onChange="onChange"
                    :placeholder="searchPlaceholder"
                    :options="options"
                    :noOptionsText="noOptionsText"
                    :sku="partNumberFromQuery"
                >
                </fmmp-async-autocomplete>
            </div>
        </div>
        <div v-if="documentsLoading" class="three-quarters-loader"></div>
        <div v-else-if="documents.length > 0" class="search-result-container">
            <search-results :partNumber="selectedPartNumber" :documents="documents"
                :buttonText="buttonText"></search-results>
        </div>
        <h4 v-else-if="documents.length === 0 && selectedPartNumber" class="not-found">
            {{ getTranslation("We’re sorry, we don’t have an installation guide for the product") }}&nbsp;{{
            selectedPartNumber }}
            {{ getTranslation("you have entered") }}
        </h4>
    </div>
</template>

<script>
import { ASSET_TYPES, SUB_TITLE, NAPA_BG_COLOR } from "./constants";

export default Vue.component("documents-autocomplete", {
    data() {
        return {
            placeholder: "Search Part Number",
            noOptionsText: "Please enter at least 4 characters",
            subTitle: SUB_TITLE,
            napaBgColor: NAPA_BG_COLOR,
            options: [],
            documents: [],
            selectedPartNumber: "",
            documentsLoading: false,
            partNumberFromQuery: "",
            isSku: false,
        };
    },
    props: {
        bgImagePath: {
            type: String,
            default: "",
        },
        brand: {
            type: String,
            default: "",
        },
        buttonText: {
            type: String,
            default: "",
        },
        napaLogoPath: {
            type: String,
            default: "",
        },
        searchTitle: {
            type: String,
        },
        searchDescription: {
            type: String,
        },
        searchPlaceholder: {
            type: String,
        },
    },
    mounted() {
        const params = new URLSearchParams(window.location.search);
        const sku = params.get('sku');

        if(sku) {
            this.partNumberFromQuery = sku;
            this.isSku = true;
            this.selectedPartNumber = sku;
            this.onSearch(sku, true);
        }
    },
    methods: {
        getTranslation(text) {
            return Vue.i18n.get(text);
        },
        isNapaBrand() {
            return this.brand === "napa";
        },
        onSearch(value, loading) {
            Vue.CatalogApi.CatalogApiService.autocompleteByPartNumber(
                decodeURIComponent(value),
                this.brand === "napa" ? window.brand : this.brand,
            )
                .then((resp) => {
                    this.options = resp.products.map((product) => ({
                        brand: product.brand,
                        name: product.part_number,
                    }));
                    if (this.isSku && this.options.length > 0) {
                        this.getDocuments(value, this.options[0].brand);
                        this.selectedPartNumber = value;
                    } else if (this.isSku) {
                        this.selectedPartNumber = value;
                        this.getDocuments(value);
                    }
                    this.isSku = false;
                })
                .catch((error) => {
                    console.error("Error fetching parts:", error);
                })
                .finally(() => {
                    if (typeof loading === "function") {
                        loading(false);
                    }
                });
        },
        onChange(part) {
            if (part) {
                this.selectedPartNumber = part.name;
                this.getDocuments(part.name, part.brand);
            } else {
                this.clearSearchResults();
            }
        },

        clearSearchResults() {
            this.selectedPartNumber = "";
            this.documents = [];
            this.options = [];
        },

        getDocuments(partNumber, brandCode) {
            this.documentsLoading = true;
            if (brandCode && partNumber) {
                Vue.CatalogApi.CatalogApiService.getPartAssetsDetails({
                    partNumber,
                    brandCode,
                    assetType: ASSET_TYPES.ins,
                })
                    .then((partDetails) => {
                        const productDocuments = partDetails.flatMap(partDetail => partDetail.dam_assets?.productDocuments || []);

                        if (!productDocuments || productDocuments.length === 0) {
                            return [];
                        }
                        return this.getPdfUrls(productDocuments);
                    })
                    .then((response) => {
                        this.documents = response.filter(Boolean);
                    })
                    .catch((error) => {
                        console.error("Error fetching documents:", error);
                        this.documents = [];
                    })
                    .finally(() => {
                        this.documentsLoading = false;
                    });
            } else {
                this.documents = [];
                this.documentsLoading = false;
            }
        },
        getPdfUrls(documents) {
            return Promise.all(
                documents.map((doc) => {
                    if (doc.url && !doc.url.startsWith('https://')) {
                        return Vue.CatalogApi.CatalogHttpService.getPdfUrl(doc.url)
                            .then((pdfUrl) => ({
                                ...doc,
                                file: pdfUrl,
                            }))
                            .catch(() => null);
                    } else {
                        return {
                            ...doc,
                            file: doc.url    
                        }
                    }
                }),
            );
        },
    },
});
</script>
