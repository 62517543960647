<template>
    <div class="error-message" :class="{ small_desktop, tablet }">
        <i class="fal fa-exclamation-triangle" />
        <fmmp-i18n :text="message" />
    </div>
</template>

<script>
export default Vue.component("fmmp-error-message", {
    props: {
        small_desktop: {
            type: Boolean,
            default: false,
        },
        tablet: {
            type: Boolean,
            default: false,
        },
        message: {
            type: String,
            default: "",
        },
    },
});
</script>
