var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "search-results" },
    [
      _c("h3", { staticClass: "title" }, [
        _vm._v(_vm._s(_vm.getTranslation("search results")))
      ]),
      _vm._v(" "),
      _vm._l(_vm.documents, function(document) {
        return _c("div", { key: document.index, staticClass: "list-item" }, [
          _c("div", { staticClass: "item-description" }, [
            _c(
              "a",
              {
                attrs: {
                  href: document.file,
                  target: "_blank",
                  rel: "noreferrer noopener",
                  type: "application/pdf"
                }
              },
              [_vm._v("\n                " + _vm._s(_vm.partNumber))]
            ),
            _vm._v(" "),
            _c("div", [
              _vm._v(
                "\n                " +
                  _vm._s(_vm.getTranslation(document.title)) +
                  "\n                "
              ),
              document.language
                ? _c("span", [
                    _vm._v(
                      " (" +
                        _vm._s(_vm.getTranslation(document.language)) +
                        ") "
                    )
                  ])
                : _vm._e()
            ])
          ]),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "button-main",
              attrs: {
                href: document.file,
                download: _vm.partNumber,
                type: "application/pdf"
              }
            },
            [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.getTranslation(_vm.computedButtonText))
              )
            ]
          )
        ])
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }