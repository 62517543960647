<template>
    <div class="driv-part-detail-page-other-media">
        <div v-if="documents.length">
            <div class="driv-part-detail-page-tab-data-header">
                {{ getTranslation("Documents") }}
            </div>
            <div class="media-container">
                <a
                    v-for="document in documents"
                    :key="document.index"
                    class="document"
                    :href="document.file"
                    target="_blank"
                    rel="noreferrer noopener"
                    type="application/pdf"
                >
                    <div class="document-title">
                        {{ document.title }}
                    </div>
                    <div v-if="document.language" class="document-language">
                        {{ getTranslation("language") }}: {{ document.language }}
                    </div>
                </a>
            </div>
        </div>
        <div v-if="videosWithIframeUrl.length">
            <div class="driv-part-detail-page-tab-data-header">
                {{ getTranslation("Videos") }}
            </div>
            <div class="media-container">
                <iframe
                    v-for="video in videosWithIframeUrl"
                    :key="video.index"
                    class="video"
                    :src="video.iframeUrl"
                    allowfullscreen
                />
            </div>
        </div>
    </div>
</template>

<script>
import { getTranslation } from "../../../../common/partFinderCorporate.helpers";

export default Vue.component("driv-part-detail-other-media", {
    props: {
        documents: {
            type: Array,
            default: () => [],
        },
        videos: {
            type: Array,
            default: () => [],
        },
    },
    computed: {
        videosWithIframeUrl() {
            return this.videos.map((data) => ({
                ...data,
                iframeUrl: `https://www.youtube.com/embed${data.url.match(/\w+(\/.+$)/)[1]}`,
            }));
        },
    },
    methods: {
        getTranslation
    },
    beforeDestroy() {
        this.documents.forEach((doc) => {
            window.URL.revokeObjectURL(doc.file);
        });
    },
});
</script>
