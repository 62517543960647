var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      staticClass: "part-number-search-form",
      on: {
        submit: function($event) {
          $event.preventDefault()
          $event.stopPropagation()
          return _vm.handleSearch.apply(null, arguments)
        }
      }
    },
    [
      _c("button", {
        staticClass: "button-search button-icon",
        attrs: { type: "submit" }
      }),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.partNumberValue,
            expression: "partNumberValue"
          }
        ],
        attrs: {
          type: "text",
          placeholder: _vm.partNumberSearchPlaceholder,
          required: ""
        },
        domProps: { value: _vm.partNumberValue },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.partNumberValue = $event.target.value
          }
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }