<template>
    <div class="search-results">
        <h3 class="title">{{ getTranslation("search results") }}</h3>
        <div class="list-item" v-for="document in documents" :key="document.index">
            <div class="item-description">
                <a
                    :href="document.file"
                    target="_blank"
                    rel="noreferrer noopener"
                    type="application/pdf"
                >
                    {{ partNumber }}</a
                >
                <div>
                    {{ getTranslation(document.title) }}
                    <span v-if="document.language"> ({{ getTranslation(document.language) }}) </span>
                </div>
            </div>
            <a
                class="button-main"
                :href="document.file"
                :download="partNumber"
                type="application/pdf"
            >
                {{ getTranslation(computedButtonText) }}</a
            >
        </div>
    </div>
</template>

<script>
import { DEFAULT_BUTTON_TEXT } from "./constants";

export default Vue.component("search-results", {
    props: {
        documents: {
            type: Array,
            default: [],
        },
        partNumber: {
            type: String,
            default: "",
        },
        buttonText: {
            type: String,
        },
    },
    methods: {
        getTranslation(text) {
            return Vue.i18n.get(text);
        },
    },
    computed: {
        computedButtonText() {
            return this.buttonText.trim() ? this.buttonText : DEFAULT_BUTTON_TEXT;
        },
    },
});
</script>
