<template>
    <div class="refine-search-fields">
        <label v-if="showLabels" class="field-label">{{  placeholder }}</label>
        <input
            type="text"
            :placeholder="placeholder"
            v-model.trim="currentPartNumber"
        />
        <driv-refine-search-btn :disabled="!currentPartNumber" :onClick="handleSearch">
        </driv-refine-search-btn>
    </div>
</template>
<script>
import { PART_FINDER_CORPORATE_SEARCH_TYPES } from "../../../../common/partFinderCorporate.constants";
import {
    createUrlToGoToSearchResults,
    getTranslation,
} from "../../../../common/partFinderCorporate.helpers";

export default Vue.component("driv-refine-part-number-search", {
    data() {
        return {
            currentPartNumber: "",
            placeholder: this.getTranslation('Part #'),
        };
    },
    props: {
        partNumber: {
            type: String,
            default: "",
        },
        showLabels: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        resultsPagePath: () => {
            return window.location.pathname.replace(".html", "");
        },
    },
    methods: {
        getTranslation,
        handleSearch() {
            if (this.resultsPagePath && this.currentPartNumber) {
                const queryObj = {
                    searchType: PART_FINDER_CORPORATE_SEARCH_TYPES.PART_NUMBER_SEARCH,
                    part: this.currentPartNumber,
                };

                const url = createUrlToGoToSearchResults({ queryObj, path: this.resultsPagePath });

                Vue.Global.Analytics.trackEvent(
                    "part-search",
                    "search-part-number",
                    this.currentPartNumber,
                    null,
                    {
                        callback: function () {
                            window.location.assign(url);
                        },
                    },
                );
            }
        },
    },
    created() {
        if (this.partNumber) {
            this.currentPartNumber = this.partNumber;
        }
    },
});
</script>
