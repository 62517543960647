<template>
    <form
        id="vin-number-form"
        class="driv-part-finder-vin-tab"
        @submit.prevent.stop="handleSearch"
        action=""
        method="get"
    >
        <div class="vin-tab-input">
            <input
                type="text"
                maxlength="17"
                :placeholder="getTranslation('17-character VIN Number')"
                v-model="vinNumber"
                @keydown="nameKeydown($event)"
            />
        </div>
        <div class="search-tools">
            <button
                type="submit"
                form="vin-number-form"
                :disabled="isDisabled"
                class="button-main vin-tab-button"
            >
                <fmmp-i18n text="look it up" />
            </button>
            <fmmp-error-message v-if="errorMessage" message="invalid vin number" />
            <fmmp-error-message v-if="errorMessageforMX" message="The VIN does not have information yet" />
        </div>
    </form>
</template>

<script>
import {
    PART_FINDER_CORPORATE_SEARCH_TYPES,
    VIN_LP_VEHICLE_TYPE,
} from "../../../common/partFinderCorporate.constants";
import {
    createUrlToGoToSearchResults,
    createQueryStringForFilters,
    mapVinOrLicensePlateResponse,
    getTranslation,
} from "../../../common/partFinderCorporate.helpers";
import "../../../common/search-error-message/ErrorMessage.vue";

export default Vue.component("tab-vin", {
    props: {
        searchResultsPath: {
            type: String,
            default: "",
        },
        subBrand: { type: String, default: "" },
    },
    data() {
        return {
            vinNumber: null,
            autocompleteConfig: {
                minLength: 0,
                delay: 0,
                html: false,
                maxHeight: "300px",
                onlySelectValid: true,
                searchOnFocus: false,
            },
            errorMessage: false,
            errorMessageforMX: false
        };
    },
    methods: {
        handleSearch() {
            Vue.CatalogApi.CatalogApiService.getVinPartsList({
                vin: this.vinNumber,
                brand: this.subBrand,
            })
                .then((resp) => {
                    const { year, make, model, ...filtersValues } = resp;
                    const ymmValues = mapVinOrLicensePlateResponse({ year, make, model });

                    if (this.searchResultsPath) {
                        const queryObj = {
                            ...ymmValues,
                            type: { value: VIN_LP_VEHICLE_TYPE },
                            searchType: PART_FINDER_CORPORATE_SEARCH_TYPES.VEHICLE,
                        };

                        const url = createUrlToGoToSearchResults({
                            path: this.searchResultsPath,
                            queryObj,
                        });

                        const urlWithFilters = `${url}#${createQueryStringForFilters(
                            filtersValues,
                        )}`;

                        Vue.Global.Analytics.trackEvent(
                            "part-search",
                            "search-part-filter",
                            this.vinNumber,
                            null,
                            {
                                callback: function () {
                                    window.location.assign(urlWithFilters);
                                },
                            },
                        );
                    }
                })
                .catch( error => {
                    switch (error.response.status) {
                    case 400:
                        this.errorMessage = true;
                        break;
                    case 409:
                        this.errorMessageforMX = true;
                        break;
                    default:
                        this.errorMessage = true;
                        break;
                    }
                });
        },
        getTranslation,
        nameKeydown(e) {
            this.errorMessage = false;
            if (!/^[a-zA-Z0-9_]+$/.test(e.key)) {
                this.errorMessage = true;
                console.log("Invalid Character entered");
                e.preventDefault();
            }
        },
    },
    computed: {
        isDisabled() {
            return !this.vinNumber;
        },
    },
});
</script>
