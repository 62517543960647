<template>
    <div class="driv-part-finder-engine-tab">
        <form
            class="driv-part-common search-container with-application-type"
            @submit.prevent.stop="handleSearch"
        >
            <fmmp-autocomplete v-if="isDCP"
                :class="`driv-part-common brands`"
                :config="autocompleteConfig"
                :id="brandItems.name"
                :name="brandItems.name"
                :items="dcpBrands"
                v-model="brandItems.model"
                :placeholder="getTranslation('Brands')"
                :loading="initialField.loading"
                @input="handleBrandChange"
            ></fmmp-autocomplete>
            <fmmp-autocomplete v-else
                :class="`driv-part-common ${initialField.name}`"
                :config="autocompleteConfig"
                :id="initialField.name"
                :name="initialField.name"
                :items="initialField.items"
                v-model="initialField.model"
                :placeholder="getTranslation(initialField.placeholder)"
                :loading="initialField.loading"
                @input="initialField.onInput"
            ></fmmp-autocomplete>
            <div v-if="initialField.model" class="engine-search-fields-wrapper">
                <div :style="[isDCP ? {'display': 'flex'} : {'display' : 'block'}]">
                    <fmmp-autocomplete
                        v-for="field in options"
                        :key="field"
                        :class="`driv-part-common ${field}`"
                        :config="autocompleteConfig"
                        :id="field"
                        :name="field"
                        :items="fieldsConfig[field].items"
                        :disabled="fieldsConfig[field].disabled"
                        v-model="fieldsConfig[field].model"
                        :placeholder="getTranslation(fieldsConfig[field].placeholder)"
                        :loading="fieldsConfig[field].loading"
                        @input="(value) => handleFieldInput(field, value)"
                    ></fmmp-autocomplete>
                </div>
                <button
                    type="submit"
                    class="button-main search"
                    v-if="searchButton"
                    :disabled="searchButton.isDisabled"
                >
                    <fmmp-i18n class="mobile" text="search" />
                    <fmmp-i18n class="desktop" text="look it up" />
                </button>
            </div>
        </form>
    </div>
</template>

<script>
import configs from "../../../common/engine-search/EngineSearchConfig";
import { getModelFromFieldObject, resetField, getBrandsOptionsFromString} from "../../../common/engine-search/helpers";
import { FIELDS } from "../../../common/engine-search/constants";
import { PART_FINDER_CORPORATE_SEARCH_TYPES } from "../../../common/partFinderCorporate.constants";
import {
    createUrlToGoToSearchResults,
    getTranslation,
} from "../../../common/partFinderCorporate.helpers";
import { getOptionsConfigBySelectedApplicationTypes } from "../../../common/ymm-search/helpers";
import { checkForEnginePerformance } from "../../../driv-part-results-refine-search/clientlibs/src/helpers";

const { fieldsConfig, optionsConfig, searchButton, initialField, brandItems } = configs;

export default Vue.component("tab-engine", {
    props: {
        engineTypes: {
            type: String,
            default: "",
        },
        searchResultsPath: {
            type: String,
            default: "",
        },
        heavyDutyBrands: {
            type: Array,
            required: false,
            default: () => []
        }
    },
    data() {
        return {
            autocompleteConfig: {
                minLength: 0,
                delay: 0,
                html: false,
                maxHeight: "300px",
                onlySelectValid: true,
                searchOnFocus: false,
            },
            optionsConfig,
            fieldsConfig: {
            ...fieldsConfig,  
                [FIELDS.HEAVY_DUTY_BRANDS]: {  
                    model: null,
                    items: this.dcpBrands,
                    placeholder: "Brands",
                    loading: false,
                    onInput: this.onBrandSelected,
                }
            },
            searchButton,
            initialField,
            isDCP: false,
            dcpBrands: [],
            brandcode:"",
            brandItems,
        };
    },
    computed: {
        config() {
            return getOptionsConfigBySelectedApplicationTypes({
                optionsConfig: this.optionsConfig,
                type: this.initialField.model,
            });
        },
        options() {
            return Object.keys(this.config.fields).filter((option) => this.fieldsConfig[option]);
        },
        isEnginePerformance() {
            return checkForEnginePerformance(this.initialField);
        },
        isEngineHeavyDuty() {
            const searchTypeId = getModelFromFieldObject(this.initialField)?.value;
            return searchTypeId === Vue.PartFinder.LOOK_UP_IDS.HEAVY_DUTY;
        },
        currentSearchType() {
            if (this.isEnginePerformance) {
                return PART_FINDER_CORPORATE_SEARCH_TYPES.ENGINE_PERFORMANCE;
            } else if (this.isEngineHeavyDuty) {
                return PART_FINDER_CORPORATE_SEARCH_TYPES.ENGINE_HEAVY_DUTY;
            }
            return PART_FINDER_CORPORATE_SEARCH_TYPES.ENGINE;
        },
    },
    watch: {
        engineTypes: function (newEngineTypes) {
            resetField(this.initialField);
            this.initialField.getItems(newEngineTypes);
        },
        "initialField.items": function (newItems) {
            if (newItems.length === 1) {
                this.initialField.model = newItems[0];
                this.initialField.onInput(newItems[0]);
            }
        },
    },
    methods: {
        getTranslation,
        handleFieldInput(field, value) {
            this.config.fields[field].onInput(value);
        },
        handleSearch() {
            if (this.searchResultsPath) {
                const vehicleGroupIds = getModelFromFieldObject(this.initialField);
                const engineMfr = getModelFromFieldObject(this.fieldsConfig[FIELDS.MANUFACTURER]);
                const performanceMfr = getModelFromFieldObject(
                    this.fieldsConfig[FIELDS.PERFORMANCE_MFRS],
                );
                const performanceBase = getModelFromFieldObject(
                    this.fieldsConfig[FIELDS.PERFORMANCE_BASE],
                );
                const engineBase = getModelFromFieldObject(this.fieldsConfig[FIELDS.BASE]);
                const engineUsage = getModelFromFieldObject(this.fieldsConfig[FIELDS.USAGE]);
                const vehicle = getModelFromFieldObject(this.fieldsConfig[FIELDS.VEHICLE]);
                const heavyMfr = getModelFromFieldObject(this.fieldsConfig[FIELDS.HEAVY_DUTY_MFRS]);
                const heavyBase = getModelFromFieldObject(
                    this.fieldsConfig[FIELDS.HEAVY_DUTY_BASE],
                );
                const brands = getModelFromFieldObject(this.fieldsConfig[FIELDS.HEAVY_DUTY_BRANDS]);


                let queryObj = {};

                if (this.isDCP) {
                    const brand_codes = fieldsConfig[FIELDS.HEAVY_DUTY_BRANDS]?.model?.value;
                    queryObj = {
                        searchType: this.currentSearchType,
                        vehicleGroupIds,
                        ...(engineMfr?.value ? { engineMfr } : {}),
                        ...(performanceMfr?.value ? { performanceMfr } : {}),
                        ...(performanceBase?.value ? { performanceBase } : {}),
                        ...(engineBase?.value ? { engineBase } : {}),
                        ...(engineUsage?.value ? { engineUsage } : {}),
                        ...(vehicle?.value ? { vehicle } : {}),
                        ...(heavyMfr?.value ? { heavyMfr } : {}),
                        ...(heavyBase?.value ? { heavyBase } : {}),
                        ...(brands?.value ? { brands } : {}),
                        own_brand: window.brand,
                        brand_codes: brand_codes
                    };

                } else {
                    queryObj = {
                        searchType: this.currentSearchType,
                        vehicleGroupIds,
                        ...(engineMfr?.value ? { engineMfr } : {}),
                        ...(performanceMfr?.value ? { performanceMfr } : {}),
                        ...(performanceBase?.value ? { performanceBase } : {}),
                        ...(engineBase?.value ? { engineBase } : {}),
                        ...(engineUsage?.value ? { engineUsage } : {}),
                        ...(vehicle?.value ? { vehicle } : {}),
                        ...(heavyMfr?.value ? { heavyMfr } : {}),
                        ...(heavyBase?.value ? { heavyBase } : {}),
                    };
                }

                const url = createUrlToGoToSearchResults({
                    queryObj,
                    path: this.searchResultsPath,
                });

                const eventParams = [
                    vehicleGroupIds.value,
                    Object.values(queryObj)
                        .map((item) => item.label)
                        .join("~"),
                ].join("|");

                Vue.Global.Analytics.trackEvent(
                    "part-search",
                    "search-part-filter",
                    eventParams,
                    null,
                    {
                        callback: function () {
                            window.location.assign(url);
                        },
                    },
                );
            }

            return false;
        },
        handleBrandChange(brand) {
            this.$set(this.fieldsConfig[FIELDS.HEAVY_DUTY_BRANDS], 'model', brand);
            this.brandItems.getSelectedBrand(brand);
            this.brandItems.getItems(this.engineTypes);         
        }
    },
    created() {
        this.isDCP = window.brand === 'commercial-parts' ? true : false;
        if (this.engineTypes && !this.isDCP) {
            this.initialField.getItems(this.engineTypes);
        } else if (this.isDCP) {
            this.dcpBrands = getBrandsOptionsFromString(this.heavyDutyBrands);
        }
    },
    beforeDestroy() {
        resetField(this.initialField);
    },
});
</script>
