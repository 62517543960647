var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      staticClass: "button-main search",
      attrs: { disabled: _vm.isDisabled },
      on: { click: _vm.onClick }
    },
    [_c("fmmp-i18n", { attrs: { text: "search" } })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }