var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "error-message",
      class: { small_desktop: _vm.small_desktop, tablet: _vm.tablet }
    },
    [
      _c("i", { staticClass: "fal fa-exclamation-triangle" }),
      _vm._v(" "),
      _c("fmmp-i18n", { attrs: { text: _vm.message } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }