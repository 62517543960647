<template>
    <form
        id="license-plate-form"
        class="driv-part-finder-license-plate-tab"
        @submit.prevent.stop="handleSearch"
        action=""
        method="get"
    >
        <fmmp-autocomplete
            class="license-plate-tab-dropdown shortened"
            :placeholder="dropdownPlaceholder"
            :config="autocompleteConfig"
            :items="states"
            v-model="stateInDropdown"
        ></fmmp-autocomplete>
        <div class="license-plate-tab-input shortened">
            <input
                type="text"
                maxlength="7"
                :placeholder="getTranslation('USA License Plate Number')"
                v-model="licensePlateNumber"
                @input="inputChangeHandler"
            />
        </div>
        <div class="search-tools">
            <button
                type="submit"
                form="license-plate-form"
                :disabled="isDisabled"
                class="button-main license-plate-tab-button"
            >
                <fmmp-i18n text="look it up" />
            </button>
            <fmmp-error-message v-if="errorMessage" message="invalid license plate number" />
        </div>
    </form>
</template>

<script>
import {
    PART_FINDER_CORPORATE_SEARCH_TYPES,
    VIN_LP_VEHICLE_TYPE,
} from "../../../common/partFinderCorporate.constants";
import {
    createUrlToGoToSearchResults,
    getTranslation,
    mapLicensePlateStatesArray,
    mapVinOrLicensePlateResponse,
    createQueryStringForFilters,
} from "../../../common/partFinderCorporate.helpers";
import "../../../common/search-error-message/ErrorMessage.vue";

export default Vue.component("tab-license-plate", {
    props: {
        searchResultsPath: {
            type: String,
            default: "",
        },
        subBrand: { type: String, default: "" },
    },
    data() {
        return {
            states: [],
            stateInDropdown: null,
            licensePlateNumber: null,
            dropdownPlaceholder: this.getTranslation("State"),
            autocompleteConfig: {
                minLength: 0,
                delay: 0,
                html: false,
                maxHeight: "300px",
                onlySelectValid: true,
                searchOnFocus: false,
            },
            errorMessage: false,
        };
    },
    methods: {
        handleSearch() {
            Vue.CatalogApi.CatalogApiService.getLicensePlateList({
                plateNumber: this.licensePlateNumber,
                code: this.stateInDropdown?.id,
                brand: this.subBrand,
            })
                .then((resp) => {
                    const { year, make, model, ...filtersValues } = resp;
                    const ymmValues = mapVinOrLicensePlateResponse({ year, make, model });

                    if (this.searchResultsPath) {
                        const queryObj = {
                            ...ymmValues,
                            type: { value: VIN_LP_VEHICLE_TYPE },
                            searchType: PART_FINDER_CORPORATE_SEARCH_TYPES.VEHICLE,
                        };

                        const url = createUrlToGoToSearchResults({
                            path: this.searchResultsPath,
                            queryObj,
                        });

                        const urlWithFilters = `${url}#${createQueryStringForFilters(
                            filtersValues,
                        )}`;

                        Vue.Global.Analytics.trackEvent(
                            "part-search",
                            "search-part-filter",
                            `${this.stateInDropdown?.label} ~ ${this.licensePlateNumber}`,
                            null,
                            {
                                callback: function () {
                                    window.location.assign(urlWithFilters);
                                },
                            },
                        );
                    }
                })
                .catch(() => {
                    this.errorMessage = true;
                });
        },
        getTranslation,
        inputChangeHandler() {
            this.errorMessage = false;
        },
    },
    computed: {
        isDisabled() {
            return !(this.stateInDropdown && this.licensePlateNumber);
        },
    },
    created() {
        Vue.CatalogApi.CatalogApiService.getLicensePlateStates(this.subBrand).then((response) => {
            this.states = mapLicensePlateStatesArray(response);
        });
    },
    watch: {
        stateInDropdown: {
            handler: function () {
                if (this.errorMessage) this.errorMessage = false;
            },
        },
    },
});
</script>
