var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      staticClass: "driv-part-finder-license-plate-tab",
      attrs: { id: "license-plate-form", action: "", method: "get" },
      on: {
        submit: function($event) {
          $event.preventDefault()
          $event.stopPropagation()
          return _vm.handleSearch.apply(null, arguments)
        }
      }
    },
    [
      _c("fmmp-autocomplete", {
        staticClass: "license-plate-tab-dropdown shortened",
        attrs: {
          placeholder: _vm.dropdownPlaceholder,
          config: _vm.autocompleteConfig,
          items: _vm.states
        },
        model: {
          value: _vm.stateInDropdown,
          callback: function($$v) {
            _vm.stateInDropdown = $$v
          },
          expression: "stateInDropdown"
        }
      }),
      _vm._v(" "),
      _c("div", { staticClass: "license-plate-tab-input shortened" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.licensePlateNumber,
              expression: "licensePlateNumber"
            }
          ],
          attrs: {
            type: "text",
            maxlength: "7",
            placeholder: _vm.getTranslation("USA License Plate Number")
          },
          domProps: { value: _vm.licensePlateNumber },
          on: {
            input: [
              function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.licensePlateNumber = $event.target.value
              },
              _vm.inputChangeHandler
            ]
          }
        })
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "search-tools" },
        [
          _c(
            "button",
            {
              staticClass: "button-main license-plate-tab-button",
              attrs: {
                type: "submit",
                form: "license-plate-form",
                disabled: _vm.isDisabled
              }
            },
            [_c("fmmp-i18n", { attrs: { text: "look it up" } })],
            1
          ),
          _vm._v(" "),
          _vm.errorMessage
            ? _c("fmmp-error-message", {
                attrs: { message: "invalid license plate number" }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }