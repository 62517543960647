<template>
    <div v-show="showPartFinder">
        <div class="driv-tabs-list__container" ref="container">
            <div class="driv-tabs-list__header">
                <p class="driv-tabs-list__title">
                    <fmmp-i18n text="Parts Finder"></fmmp-i18n>
                </p>
                <driv-tabs-list
                    v-show="isMoreThanOneTab"
                    :tabs="tabs"
                    :currentTabValue="currentTab"
                    @select="handleSelect($event)"
                ></driv-tabs-list>
                <div class="driv-select-wrapper">
                    <fmmp-autocomplete
                        v-show="isMoreThanOneTab"
                        :items="tabs"
                        :value="currentTabOption"
                        :config="autocompleteConfig"
                        @input="handleInput($event)"
                    >
                    </fmmp-autocomplete>
                </div>
            </div>
            <component
                :is="currentTabContent"
                class="driv-tabs-list__content"
                :searchResultsPath="searchResultsPath"
                :vehicleTypes="vehicleTypes"
                :defaultVehicleTypeId="defaultVehicleTypeId"
                :engineTypes="engineTabTypes"
                :specificationTypes="specificationTypes"
                :specificationBrandType="specificationBrandType"
                :torqueContentSilos="torqueSilosToGroupIdMap"
                :subBrand="subBrand"
                :heavyDutyBrands="parsedHeavyDutyBrands"
                :tags="tags"
            ></component>
            <input
                type="button"
                v-if="showPartListButton"
                :value="partListButtonText"
                class="open-partslist-button"
                @click="openPartsListModal"
            />
            <driv-part-list-modal
                :partListModal="partsListModal"
                @updatePartListModal="updatePartListModal"
                :disableAddToCart="disableAddToCart"
            ></driv-part-list-modal>
        </div>
    </div>
</template>

<script>
import {
    PART_FINDER_CORPORATE_SEARCH_TYPES,
    PART_FINDER_CORPORATE_TABS,
} from "../../../common/partFinderCorporate.constants";
import { getBrandsFromTags } from "../../../common/ymm-search/helpers";
import { getTranslation } from "../../../common/partFinderCorporate.helpers";
import "../../../driv-part-detail-page/clientlibs/src/partListModal.vue";

const HYBRIS_USER_ROLES_WITH_PART_FINDER = {
    FMB2BB: "FMB2BB",
    FMB2C: "FMB2C",
	FMCSR: "FMCSR",
    FMBUVOR: "FMBUVOR",
    FMB2T: "FMB2T"
};
const NOT_AUTHORIZED_USER = "User is not authorized";
const INVALID_TOKEN = "InvalidTokenError";

export default Vue.component("driv-part-finder-corporate", {
    props: {
        initialTab: {
            type: String,
            default: "",
        },
        tabsToDisplay: {
            type: String,
            default: "",
        },
        searchResultsPath: {
            type: String,
            default: "",
        },
        vehicleTypes: {
            type: String,
            default: "",
        },
        defaultVehicleTypeId: {
            type: String,
            default: "",
        },
        engineTypes: {
            type: String,
            default: "",
        },
        performanceTypes: {
            type: String,
            default: "",
        },
        specificationTypes: {
            type: String,
            default: "",
        },
        specificationBrandType: {
            type: String,
            default: "",
        },
        tags: {
            type: String,
            default: "",
        },
        showPartListButton: {
            type: Boolean,
            default: false,
        },
        disableAddToCart: {
            type: Boolean,
            default: false,
        },
        partListButtonText: {
            type: String,
            default: "",
        },
        torqueSilosToGroupIdMap: {
            type: String,
            default: "",
        },
        disableAbsolutePositioning: {
            type: Boolean,
            default: false,
        },
        heavyduty: {
            type: [Array, String],
            required: false,
            default: () => []
        }
    },
    data() {
        return {
            tabs: PART_FINDER_CORPORATE_TABS,
            currentTab: this.initialTab,
            autocompleteConfig: {
                minLength: 0,
                delay: 0,
                html: false,
                maxHeight: "300px",
                onlySelectValid: true,
                searchOnFocus: false,
            },
            showPartFinder: false,
            subBrand: "",
            partsListModal: false,
        };
    },
    computed: {
        engineTabTypes() {
            if (this.isPerformanceTab) return this.performanceTypes;
            return this.engineTypes;
        },
        currentTabContent() {
            if (this.isPerformanceTab) return `tab-${PART_FINDER_CORPORATE_SEARCH_TYPES.ENGINE}`;
            return `tab-${this.currentTab.toLowerCase()}`;
        },
        currentTabOption() {
            return this.tabs.find((option) => option.value === this.currentTab);
        },
        isMoreThanOneTab() {
            return this.tabs.length > 1;
        },
        isPerformanceTab() {
            return (
                this.currentTab?.toLowerCase() ===
                PART_FINDER_CORPORATE_SEARCH_TYPES.ENGINE_PERFORMANCE?.toLowerCase()
            );
        },
        parsedHeavyDutyBrands() {

            if (typeof this.heavyduty === "string" && this.heavyduty !== "test") {
                try {
                    const parsedData = JSON.parse(this.heavyduty.replace(/'/g, '"')); 
                    
                    return parsedData;
                } catch (error) {
                    console.error("Failed to parse heavyDutyBrands:", error);
                    return [];
                }
            }
            return this.heavyduty; 
        },
    },
    methods: {
        getTranslation,
        handleSelect(tabValue) {
            this.currentTab = tabValue;
        },
        handleInput(option) {
            this.currentTab = option.value;
        },
        openPartsListModal() {
            this.partsListModal = !this.partsListModal;
        },
        updatePartListModal(partListModalUpdated) {
            this.partsListModal = partListModalUpdated;
        },
    },
    created() {
        const partFinderComponent = document.querySelector(".driv-part-finder-corporate");
        const typesArray = this.tabsToDisplay.split(",") || [];
        console.log('created', this.heavyduty);

        if (typesArray.length) {
            this.tabs = typesArray
                .map((type) => PART_FINDER_CORPORATE_TABS.find((option) => type === option.value))
                .filter(Boolean);
        }

        if (this.disableAbsolutePositioning) {
            partFinderComponent.classList.add("relative-position");
        }

        this.subBrand = getBrandsFromTags(this.tags);

        Vue.HybrisService.getRoles().then(
            (roles) => {
                const hybrisUserRole = roles?.find(
                    (role) => HYBRIS_USER_ROLES_WITH_PART_FINDER[role],
                );

                if (hybrisUserRole) {
                    this.showPartFinder = true;
                    hybrisUserRole === HYBRIS_USER_ROLES_WITH_PART_FINDER.FMB2T &&
                        this.$refs.container.classList.add("withIframeB2THomePane");
                }
				
                if (hybrisUserRole === HYBRIS_USER_ROLES_WITH_PART_FINDER.FMCSR || hybrisUserRole === HYBRIS_USER_ROLES_WITH_PART_FINDER.FMBUVOR) {
                    const emulatorIframe = document.querySelector('.page-include-container')?.querySelector('iframe');
                    emulatorIframe.addEventListener('load', function (e) {
                        const IFrame = e.currentTarget;
                        const iFrameDoc = IFrame.contentWindow.document;
                        const b2bResponse = iFrameDoc.getElementsByClassName('emulateAccount');
                        if (b2bResponse.length) {
                            partFinderComponent.classList.add("csruser-with-emulator");
                        }
                    });
                }
            },
            (err) => {
                if (typeof err === "string" && err === NOT_AUTHORIZED_USER) {
                    this.showPartFinder = true;
                } else if (
                    err.errors &&
                    Array.isArray(err.errors) &&
                    err.errors.some((error) => error.type === INVALID_TOKEN)
                ) {
                    this.showPartFinder = true;
                } else console.log("Invalid authentication error", err);
            },
        );
    },
});
</script>
