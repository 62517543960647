var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "driv-part-finder-vehicle-tab" },
    [
      _c("driv-common-ymm-search", {
        staticClass: "driv-part-common with-application-type",
        attrs: {
          path: _vm.searchResultsPath,
          types: _vm.vehicleTypes,
          initialTypeId: _vm.defaultVehicleTypeId,
          searchBtnLabel: _vm.SEARCH_BTN_LABEL,
          searchType: _vm.searchType,
          tags: _vm.tags
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }