<template>
    <button
        class="button-main search"
        :disabled="isDisabled"
        @click="onClick"
    >
        <fmmp-i18n text="search" />
    </button>
</template>

<script>
export default Vue.component("driv-refine-search-btn", {
    props: {
        isDisabled: {
            type: Boolean,
            default: false,
        },
        onClick: {
            type: Function,
            required: true,
        },
    },
});
</script>
