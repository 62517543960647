<template>
    <div class="driv-part-finder-vehicle-tab">
        <driv-common-ymm-search
            class="driv-part-common with-application-type"
            :path="searchResultsPath"
            :types="vehicleTypes"
            :initialTypeId="defaultVehicleTypeId"
            :searchBtnLabel="SEARCH_BTN_LABEL"
            :searchType="searchType"
            :tags="tags"
        ></driv-common-ymm-search>
    </div>
</template>

<script>
import "../../../common/ymm-search/YMMSearch.vue";
import { PART_FINDER_CORPORATE_SEARCH_TYPES } from "../../../common/partFinderCorporate.constants";

const SEARCH_BTN_LABEL = {
    mobile: "search",
    desktop: "look it up",
};

export default Vue.component("tab-vehicle", {
    data() {
        return {
            SEARCH_BTN_LABEL,
            searchType: PART_FINDER_CORPORATE_SEARCH_TYPES.VEHICLE,
        };
    },
    props: {
        searchResultsPath: {
            type: String,
            default: "",
        },
        vehicleTypes: {
            type: String,
            default: "",
        },
        defaultVehicleTypeId: {
            type: String,
            default: "",
        },
        tags: { type: String, default: "" },
    },
});
</script>
