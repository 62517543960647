import { PROPS } from "./partDetailsPage.constants";

export const displayYearsRange = (yearsArray) => {
    const length = yearsArray.length;

    if (length === 1) {
        return yearsArray[0];
    } else if (length !== 0) {
        return `${yearsArray[0]}-${yearsArray[length - 1]}`;
    }
};

export const checkIfPropExistInPassengerCarTab = (column, product) => {
    const commonPart = product[column.property];
    switch (column.property) {
        case PROPS.YEARS:
            return commonPart && displayYearsRange(product.years);
        case PROPS.CATEGORY:
            return commonPart && commonPart.part_type_value;
        case PROPS.QUANTITY:
        case PROPS.ENGINE_VERSION:
        case PROPS.ENGINE_BASE_VALUE:
            return commonPart;
        case PROPS.ENGINE_BASE:
            return commonPart && commonPart.engine_base_value;
        default:
            return commonPart && commonPart.value;
    }
};

export const filterColumnsForMobileView = (columns) => {
    return columns.filter((col) => {
        if (
            col.property !== PROPS.ENGINE_VIN &&
            col.property !== PROPS.DRIVE_WHEEL &&
            col.property !== PROPS.ENGINE_DESIGNATION
        ) {
            return col;
        }
    });
};
